import * as React from "react";
import { Container } from "./styled";
import { OkayCheckIcon } from "../Icons/checkbox";

const CopyNotification = props => {
  const { message } = props;

  return (
    <Container id="notification-text">
      <OkayCheckIcon />
      {message}
    </Container>
  );
};

export default CopyNotification;
