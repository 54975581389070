import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import App from "./App";
import "./styles/global.css";
import theme from "./styles/theme";
import AuthProvider from "./providers/auth/auth.provider";
import NotificationProvider from "./providers/notification/notification.provider";
import ImportProvider from "./providers/import/import.provider";

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <NotificationProvider>
            <ThemeProvider theme={theme}>
              <ImportProvider>
                <App />
              </ImportProvider>
            </ThemeProvider>
          </NotificationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
