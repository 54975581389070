import { createTheme } from "@mui/material/styles";

const palette = {
  primary: {
    main: "#4D7CFE",
    dark: "#252631",
    hover: "#4973EA"
  },
  secondary: {
    light: "#98A9BC",
    main: "#f2f4f6",
    dark: "#778CA2"
  }
};

const theme = createTheme({
  palette: palette,
  typography: {
    body1: {
      fontSize: 14
    },
    fontFamily: "Rubik"
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0 14px 0 0"
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
          marginLeft: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        label: {
          lineHeight: "14px",
          fontSize: 14
        },
        containedPrimary: {
          boxShadow: "none",
          height: 46,
          "&:hover": {
            backgroundColor: palette.primary.hover,
            boxShadow: "none"
          }
        },
        containedSecondary: {
          boxShadow: "none",
          height: 46,
          color: palette.secondary.dark,
          "&:hover": {
            color: palette.primary.main,
            backgroundColor: palette.secondary.main,
            boxShadow: "none"
          }
        },
        outlinedPrimary: {
          height: 46,
          color: palette.primary.main,
          border: "1px solid #4D7CFE",
          "&:hover": {
            backgroundColor: "transparent",
            color: palette.primary.main
          }
        },
        outlinedSecondary: {
          height: 46,
          color: palette.secondary.dark,
          border: `1px solid ${palette.secondary.main}`,
          "&:hover": {
            backgroundColor: "#fff",
            color: palette.primary.main,
            border: `1px solid ${palette.secondary.main}`
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 750
        },
        paper: {
          borderRadius: 0
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 20,
          padding: 25,
          "& > h2": {
            fontWeight: 400
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 30,
          backgroundColor: "#F8FAFB"
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          padding: 25
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          margin: "30px 0"
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            background: palette.primary.hover
          },
          "&.Mui-disabled": {
            opacity: 1,
            cursor: "pointer"
          }
        },
        icon: {
          color: palette.secondary.dark
        },
        previousNext: {
          "&:hover": {
            background: "none"
          }
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      },
      styleOverrides: {
        paper: {
          "&.MuiMenu-paper": {
            boxShadow: "0px 0px 9px 3px rgb(0 0 0 / 10%)"
          }
        },
        list: {
          padding: "7px 0"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette.secondary.dark,
          backgroundColor: "transparent !important",
          padding: 0,
          width: "100%",
          minWidth: 120,
          display: "block",
          boxSizing: "content-box",
          "&:hover": {
            backgroundColor: "transparent",
            color: palette.primary.main,
            "& svg > path": {
              fill: palette.primary.main
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontWeight: 500,
          border: "1px solid #E8ECEF",
          background: "white",
          padding: "8px 28px 8px 15px",
          minHeight: 50,
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center"
        },
        root: {
          "&.Mui-disabled": {
            background: "transparent"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiSelect-multiple.MuiSelect-outlined.MuiOutlinedInput-input": {
            borderRadius: 5,
            border: "1px solid #E8ECEF",
            background: "white",
            padding: "8px 28px 8px 8px",
            minHeight: 50,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
          },
          "& .MuiAutocomplete-clearIndicator": {
            display: "none"
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          border: "1px solid #E8ECEF",
          background: "white",
          minHeight: 50,
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          "& .MuiAutocomplete-popupIndicator": {
            "&:hover": {
              background: "transparent"
            }
          },
          "& .MuiAutocomplete-input": {
            "&::placeholder": {
              fontWeight: 500,
              color: "#98A9BC",
              opacity: 1
            },
            fontWeight: 500
          },
          "& .MuiChip-root": {
            padding: "4px 25px",
            background: "#f2f4f6",
            fontWeight: 400,
            fontSize: 14,
            color: "#778ca2",
            margin: 2,
            borderRadius: 5
          },
          "& .MuiChip-label": {
            paddingLeft: 0
          },
          "& .MuiOutlinedInput-root": {
            padding: 7,
            minHeight: 50
          }
        },
        paper: {
          marginTop: 5,
          padding: 0,
          maxHeight: 300,
          boxShadow: "0px 0px 9px 3px rgb(0 0 0 / 3%)",
          "& .MuiAutocomplete-listbox": {
            padding: "0",
            maxHeight: "100%"
          },
          "& .MuiAutocomplete-noOptions": {
            display: "none"
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "0 0 2px 0",
          fontSize: 14,
          color: "#778CA2",
          marginRight: 25,
          minHeight: "fit-content"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "fit-content"
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#F2F4F6",
          height: 10
        }
      }
    }
  }
});

export default theme;
