import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  min-height: ${props =>
    props.global ? "100vh" : props.height || "calc(100vh - 180px)"};
  width: 100%;
`;
