import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 560px;
  border-radius: 5px;
  height: 65px;
  padding: 0 20px;
  box-sizing: content-box;
  color: white;
  font-weight: 500;
  background: ${props => (props.severity === "error" ? "#FE4D97" : "#6DD230")};
`;
export const Close = styled.div`
  border: 1px solid white;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 20px;
  cursor: pointer;
`;
export const Content = styled.div`
  & > svg {
    margin-right: 20px;
  }
  display: flex;
  align-items: center;
  font-size: 16px;
`;
