const apiUrls = {
  production: "https://api-v2.propertymatchreis.com/",
  test: "https://property-match-api.herokuapp.com/",
  development: "http://localhost:8080/"
};

// TODO fix
export const BASE_URL =
  process.env.REACT_APP_ENV === "development" &&
  process.env.NODE_ENV === "production"
    ? apiUrls.production
    : apiUrls[process.env.REACT_APP_ENV] ?? apiUrls.production;
