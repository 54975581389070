import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  border-radius: 5px;
  padding: 15px 20px;
  box-sizing: content-box;
  font-size: 16px;
  color: white;
  font-weight: 500;
  background: #6DD230;
  & > svg {
    margin-right: 10px;
  }
`;
