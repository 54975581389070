import { createContext, useContext, useEffect, useState } from "react";
import documentsService from "../../services/documents/documents.service";
import { AuthContext } from "../auth/auth.provider";

const defaultStateForErrorModal = {
  open: false,
  data: null
};

export const ImportContext = createContext({ data: [] });

const ImportProvider = props => {
  const { children } = props;

  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [errorModal, setErrorModal] = useState(defaultStateForErrorModal);
  const [importStatus, setImportStatus] = useState(null);

  const handleCloseErrorModal = () =>
    setErrorModal(prevState => ({ open: false, data: prevState.data }));
  const handleOpenErrorModal = data => setErrorModal({ open: true, data });

  const getAllDocuments = () => {
    documentsService
      .getAllDocuments()
      .then(res => setData(res))
      .catch(e => console.log(e));
  };

  const getImportStatus = async () => {
    const status = await documentsService.getImportStatus();

    setImportStatus(status.data);
  };

  useEffect(() => {
    if (user.isLogin) {
      getAllDocuments();
      getImportStatus();
    }
  }, [user]);

  const value = {
    data,
    documentsCount: Object.keys(data)?.length,
    errorModal,
    getAllDocuments,
    getImportStatus,
    handleCloseErrorModal,
    handleOpenErrorModal,
    importStatus,
    setErrorModal,
    setImportStatus
  };

  return (
    <ImportContext.Provider value={value}>{children}</ImportContext.Provider>
  );
};

export default ImportProvider;
