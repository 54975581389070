import { axiosCustom } from "../axios/axios";
import axios from "axios";
import { BASE_URL } from "../constants";

class AuthService {
  login(email, password) {
    return axiosCustom
      .post(BASE_URL + "auth/login", {
        email: email,
        password: password
      })
      .then(response => response.data);
  }
  refreshToken() {
    return axios
      .get(`${BASE_URL}auth/refresh`, { withCredentials: true })
      .then(response => response.data);
  }
  logout() {
    return axiosCustom
      .post(`${BASE_URL}auth/logout`)
      .then(response => response.data);
  }
}

export default new AuthService();
