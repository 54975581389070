import { axiosCustom } from "../axios/axios";
import { BASE_URL } from "../constants";

class DocumentsService {
  importProperties(data) {
    return axiosCustom
      .post(BASE_URL + "documents/import-properties", data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => res.data);
  }

  getAllDocuments() {
    return axiosCustom.get(BASE_URL + "documents").then(res => res.data);
  }

  downloadAllFiles(type, importId) {
    return axiosCustom.post(BASE_URL + `documents/download/all/${type}`, {
      importId
    });
  }

  getImportResult(documentId) {
    return axiosCustom.get(
      `${BASE_URL}documents/import/result?id=${documentId}`
    );
  }

  getImportStatus() {
    return axiosCustom.get(`${BASE_URL}documents/import/status`);
  }

  getImportMapping() {
    return axiosCustom.get(`${BASE_URL}documents/import/mapping`);
  }

  updateImportMapping(body) {
    return axiosCustom.post(`${BASE_URL}documents/import/mapping`, body);
  }

  resetImportStatus() {
    return axiosCustom.post(`${BASE_URL}documents/import/reset`);
  }

  downloadDocumentByName(fileName) {
    return axiosCustom
      .get(BASE_URL + "documents/download/" + fileName)
      .then(res => res.data);
  }

  deleteDocumentByName(fileName) {
    return axiosCustom
      .delete(BASE_URL + "documents/delete/" + fileName)
      .then(res => res.data);
  }
}

export default new DocumentsService();
