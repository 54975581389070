import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
const ActivateAccount = React.lazy(() =>
  import("./components/Auth/Activate/ActivateAccount")
);
const ActivateNewPassword = React.lazy(() =>
  import("./components/Auth/Activate/ActivateNewPassword")
);
const AdminRoute = React.lazy(() =>
  import("./components/Auth/AdminRoute/AdminRoute")
);
const Contacts = React.lazy(() => import("./components/Contacts/Contacts"));
const DevRoute = React.lazy(() =>
  import("./components/Auth/DevRoute/DevRoute")
);
const EndatoPage = React.lazy(() => import("./pages/EndatoPage"));
const ImportPage = React.lazy(() => import("./pages/ImportPage"));
const Layout = React.lazy(() => import("./components/Layout/Layout"));

const Login = React.lazy(() => import("./components/Auth/Login/Login"));
const MarketingPage = React.lazy(() => import("./pages/MarketingPage"));
const NotFoundPage = React.lazy(() =>
  import("./components/NotFoundPage/NotFoundPage")
);
const Properties = React.lazy(() =>
  import("./components/Properties/Properties")
);
const RecoverPassword = React.lazy(() =>
  import("./components/Auth/RecoverPassword/RecoverPassword")
);
const Requirements = React.lazy(() =>
  import("./components/Requirements/Requirements")
);
const RequireAuth = React.lazy(() =>
  import("./components/Auth/RequireAuth/RequireAuth")
);
const ServerErrorPage = React.lazy(() =>
  import("./components/ServerErrorPage/ServerErrorPage")
);
const Settings = React.lazy(() => import("./components/Settings/Settings"));

import Loader from "./components/UI/Loader/Loader";

function App() {
  return (
    <Suspense fallback={<Loader global />}>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/activate/:token" element={<ActivateAccount />} />
          <Route
            path="/reset-password/:token"
            element={<ActivateNewPassword />}
          />
          <Route path="/recover" element={<RecoverPassword />} />
          <Route path="/" element={<Layout />}>
            <Route
              path="/contacts/*"
              element={
                <RequireAuth>
                  <Contacts />
                </RequireAuth>
              }
            />
            <Route
              path="/properties/*"
              element={
                <RequireAuth>
                  <Properties />
                </RequireAuth>
              }
            />
            <Route
              path="/requirements/*"
              element={
                <RequireAuth>
                  <Requirements />
                </RequireAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <AdminRoute>
                  <Settings />
                </AdminRoute>
              }
            />
            <Route
              path="/marketing"
              element={
                <DevRoute>
                  <MarketingPage />
                </DevRoute>
              }
            />
            <Route
              path="/endato"
              element={
                <DevRoute>
                  <EndatoPage />
                </DevRoute>
              }
            />
            <Route
              path="/import"
              element={
                <DevRoute>
                  <ImportPage />
                </DevRoute>
              }
            />

            <Route path="/500" element={<ServerErrorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
