import { Container } from "./styled";
import CircularProgress from "@mui/material/CircularProgress";

function Loader(props) {
  const { global, height } = props;
  return (
    <Container height={height} global={global}>
      <CircularProgress color="primary" />
    </Container>
  );
}

export default Loader;
