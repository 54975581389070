import axios from "axios";
import AuthService from "../auth/auth.service";

const instance = axios.create({ withCredentials: true });

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status >= 500) {
        window.location.replace("/500");
      }
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          await AuthService.refreshToken().catch(() =>
            window.location.replace("/")
          );
          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export { instance as axiosCustom };
