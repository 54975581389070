export const userRoles = {
  Admin: "super_admin",
  User: "user",
  Manager: "manager"
};

export const userRolesName = {
  [userRoles.User]: "User",
  [userRoles.Admin]: "Admin",
  [userRoles.Manager]: "Manager"
};

export const userStatuses = {
  Pending: "in_progress",
  Expired: "expired",
  Activated: "activated"
};

export const userStatusesName = {
  [userStatuses.Pending]: "Pending",
  [userStatuses.Expired]: "Expired",
  [userStatuses.Activated]: "Activated"
};
