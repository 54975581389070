import * as React from "react";
import { Close, Container, Content } from "./styled";
import { RingIcon } from "../Icons/global";

const Notification = (props) => {
  const { severity, message, onClose } = props;

  return (
    <Container severity={severity}>
      <Content id="notification-text">
        <RingIcon />
        {message}
      </Content>
      <Close onClick={onClose}>close</Close>
    </Container>
  );
};

export default Notification;
